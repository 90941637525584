const initialState = {
  variacoes: [
    {
   
    }
  ],
};
const posReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      return { ...state, variacoes: action.variacoes };
    case 'TIPO_BUSCA':
      return { ...state, tipoBusca: action.tipo };
    default:

      return state;
  }
};
export default posReducer;
